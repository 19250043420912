import React, { Fragment, useEffect, useState } from 'react'
import AmountCard from '../../components/Dashboard/AmountCard'
import ListCard from '../../components/Dashboard/ListCard'
import ListTable from '../../components/Dashboard/ListTable'
import { DatePicker, Space } from 'antd'
import moment from 'moment'
import SendAPI from '../../utils/SendAPI'
import wallet from './../../assets/img/wallet.svg'
import deposit from './../../assets/img/deposit.svg'
import withdraw from './../../assets/img/withdraw.svg'

const Dashboard = () => {
    const { RangePicker } = DatePicker
    const dateFormat = 'DD/MM/YYYY'

    const [dateStartSearch, setDateStartSearch] = useState<any>(moment())
    const [dateEndSearch, setDateEndSearch] = useState<any>(moment())
    const [dateLoadData, setDateLoadData] = useState('')

    const { sendAPIWithJWT } = SendAPI();
    const [loadingSummaries, setLoadingSummaries] = useState(true)
    const [loadingTransfers, setLoadingTransfers] = useState(true)
    const [loadingTransactionsDeposit, setLoadingTransactionsDeposit] = useState(true)
    const [loadingTransactionsWithdraw, setLoadingTransactionsWithdraw] = useState(true)

    const [dataDeposit, setDataDeposit] = useState<any>()
    const [dataWithdraw, setDataWithdraw] = useState<any>()
    const [dataBalance, setDataBalance] = useState<number>(0)
    const [dataTransactionsDeposit, setDataTransfersDeposit] = useState<any>([])
    const [dataTransactionsWithdraw, setDataTransfersWithdraw] = useState<any>([])
    const [dataTransfers, setDataTransfers] = useState<any>([])
    const getSummaries = async (dateStart: any, dateEnd: any) => {
        let url = `/summaries`
        if (dateStart !== null && dateEnd !== null) {
            const sDate = moment(dateStart).format('YYYY-MM-DD');
            const eDate = moment(dateEnd).format('YYYY-MM-DD');
            url = `${url}?sdate=${sDate}&edate=${eDate}`
        }
        const dataResponse: any = await sendAPIWithJWT({
            path: url, //account_id=1
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataDeposit(dataResponse.data.deposit)
            setDataWithdraw(dataResponse.data.withdraw)
            setDataBalance(dataResponse.data.remaining_balance)
            setDateLoadData(dataResponse.data.remaining_balance_updated_at)
        }
        setLoadingSummaries(false)
        return dataResponse
    }

    const getTransfers = async (dateStart: any, dateEnd: any) => {
        let url = `/transfers?limit=5`
        if (dateStart !== null && dateEnd !== null) {
            const sDate = moment(dateStart).format('YYYY-MM-DD');
            const eDate = moment(dateEnd).format('YYYY-MM-DD');
            url = `${url}&sdate=${sDate}&edate=${eDate}`
        }
        const dataResponse: any = await sendAPIWithJWT({
            path: url,
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataTransfers(dataResponse.data?.data)
        }
        setLoadingTransfers(false)
        return dataResponse
    }

    const getTransactions = async (dateStart: any, dateEnd: any, typeTransaction: string) => {
        let url = `/summaries/transactions?txn_debit_credit_flag=${typeTransaction}`
        if (dateStart !== null && dateEnd !== null) {
            const sDate = moment(dateStart).format('YYYY-MM-DD');
            const eDate = moment(dateEnd).format('YYYY-MM-DD');
            url = `${url}&sdate=${sDate}&edate=${eDate}`
        }
        const dataResponse: any = await sendAPIWithJWT({
            path: url, //&txn_debit_credit_flag=C&account_id=1
            // path : `/summaries/transactions?sdate=2020-10-07&edate=2020-10-08&txn_debit_credit_flag=${typeTransaction}`, //&txn_debit_credit_flag=C&account_id=1
            method: "get",
        });
        if (dataResponse.status === 200) {
            // C = รายการฝากเงิน, D = รายการถอนเงิน
            if (typeTransaction === "C") {
                setDataTransfersDeposit(dataResponse.data?.data)
                setLoadingTransactionsDeposit(false)
            } else {
                setDataTransfersWithdraw(dataResponse.data?.data)
                setLoadingTransactionsWithdraw(false)
            }
        }
        return dataResponse
    }

    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }
    const numberWithCommasWithoutDigit = (num: any) => {
        num = num.toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }

    const onSearch = () => {
        setLoadingSummaries(true)
        setLoadingTransfers(true)
        setLoadingTransactionsDeposit(true)
        setLoadingTransactionsWithdraw(true)
        getSummaries(dateStartSearch, dateEndSearch)
        getTransfers(dateStartSearch, dateEndSearch)
        getTransactions(dateStartSearch, dateEndSearch, 'C') //รายการฝากเงิน
        getTransactions(dateStartSearch, dateEndSearch, 'D') //รายการถอนเงิน
    }

    const changeSearchDate = (date: any, dateString: any) => {
        if (date !== null) {
            setDateStartSearch(date[0])
            setDateEndSearch(date[1])
        } else {
            setDateStartSearch(null)
            setDateEndSearch(null)
        }
    }

    useEffect(() => {
        onSearch()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <div className="d-lg-flex mb-4">
                    <div className="d-flex mb-3 mb-lg-0">
                        <Space
                            direction="vertical"
                            size={12}
                        >
                            <RangePicker
                                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                                defaultValue={[moment(), moment()]}
                                ranges={{
                                    'วันนี้': [moment(), moment()],
                                    'เมื่อวาน': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                    'อาทิตย์นี้': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
                                    'อาทิตย์ที่แล้ว': [moment().startOf('isoWeek').subtract(7, 'days'), moment().endOf('isoWeek').subtract(7, 'days')],
                                    'เดือนนี้': [moment().startOf('month'), moment().endOf('month')],
                                    'เดือนที่แล้ว': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                }}
                                format={dateFormat}
                                onChange={changeSearchDate}
                            />
                        </Space>
                        <button type="button" className="btn btn-outline-primary ms-3" onClick={onSearch}>ค้นหา</button>
                    </div>
                    {/* <button className="btn btn-primary ms-auto col-12 col-md-3" onClick={onSearch}>อัพเดทรายการ ฝาก - ถอน</button> */}
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <AmountCard
                            colorTheme='linear-gradient(336deg, #fa70a2, #f49f92)'
                            icon={wallet}
                            iconColorRgb='255, 255, 255'
                            textColorRgb='255, 255, 255'
                            buttonText='เบิกค่าใช้จ่าย'
                            buttonLink='/transfer'
                            amount={dataBalance ? numberWithCommas(dataBalance) : '0.00'}
                            amountType='ยอดเงินคงเหลือ'
                            amountTime={`ข้อมูลล่าสุดเมื่อ ${dateLoadData} น.`}
                            loading={loadingSummaries}
                        />
                        <div className="row">
                            <div className="col-lg-6">
                                <AmountCard
                                    icon={deposit}
                                    iconColorRgb='19, 155, 130'
                                    amount={dataDeposit ? numberWithCommas(dataDeposit?.amount) : '0.00'}
                                    amountType='โอนเงินเข้า'
                                    numList={dataDeposit ? numberWithCommasWithoutDigit(dataDeposit?.total) : '0'}
                                    loading={loadingSummaries}
                                />
                            </div>
                            <div className="col-lg-6">
                                <AmountCard
                                    icon={withdraw}
                                    iconColorRgb='223, 75, 74'
                                    amount={dataWithdraw ? numberWithCommas(dataWithdraw?.amount) : '0.00'}
                                    amountType='โอนเงินออก'
                                    numList={dataWithdraw ? numberWithCommasWithoutDigit(dataWithdraw?.total) : '0'}
                                    loading={loadingSummaries}
                                />
                            </div>
                        </div>
                        <ListTable list={dataTransfers} loading={loadingTransfers} />
                    </div>
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <ListCard
                                    list={dataTransactionsDeposit}
                                    title='โอนเงินเข้า'
                                    color='#00c9a4'
                                    symbol='+'
                                    loading={loadingTransactionsDeposit}
                                />
                            </div>
                            <div className="col-lg-6">
                                <ListCard
                                    list={dataTransactionsWithdraw}
                                    title='โอนเงินออก'
                                    color='#DF4B4A'
                                    symbol='-'
                                    loading={loadingTransactionsWithdraw}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Dashboard