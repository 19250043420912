import React, { FC } from 'react'
import styled from 'styled-components'
import MultipleData from '../../components/Dashboard/MultipleData'
import ListStatusBadge from '../../components/Elements/ListStatusBadge'
import { Table, Typography } from 'antd';
import { Link } from 'react-router-dom'

interface ListTableProps {
    list: object
    loading: boolean
}

const ListTable: FC<ListTableProps> = ({ list, loading }) => {
    const [filterText, setFilterText] = React.useState(''); // eslint-disable-line

    const statusColor: any = {
        'error': '255, 0, 0',
        'pending': '160, 160, 160',
        'processing': '24, 144, 255',
        'success': '19, 155, 130',
        'verified': '24, 144, 255',
    }

    const filteredItems = (list as any).filter(
        (item: any) => (
            (item.title && item.title.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.bank_account_name && item.bank_account_name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.bank_name && item.bank_name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.bank_account_no && item.bank_account_no.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.account_bank_name && item.account_bank_name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.company_name && item.company_name.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.remark && item.remark.toLowerCase().includes(filterText.toLowerCase())) ||
            (item.created_user_name && item.created_user_name.toLowerCase().includes(filterText.toLowerCase()))
        ),
    );

    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }

    const columns = [
        {
            title: 'ชื่อรายการ',
            render: (row: any) => (
                <TextNoWrap>
                    <MultipleData name={row.title} dateTime={row.remark &&
                        (<Typography.Paragraph ellipsis={{ rows: 4, expandable: true, symbol: "เพิ่มเติม" }}>
                            <small className="text-muted wrap">{`* ${row.remark}`}</small>
                        </Typography.Paragraph>)} wrap={true} />
                </TextNoWrap>
            ),
            width: '200px',
        },
        {
            title: ' Company',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        {row.company_name}
                    </TextNoWrap>
                )
            },
            width: '180px',
        },
        {
            title: 'โอนจาก',
            render: (row: any) => (
                <TextNoWrap>
                    <MultipleData name={row.account_bank_name} dateTime={row.account_bank_account} />
                </TextNoWrap>
            ),
        },
        {
            title: 'จำนวนเงิน',
            render: (row: any) => <TextNoWrap>{`฿ ${numberWithCommas(row.amount)}`}</TextNoWrap>,
        },
        {
            title: 'ไปยัง',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div>{row.bank_account_name ? row.bank_account_name : '-'}</div>
                        <div><small className="text-muted">{row.bank_name}</small></div>
                        <div><small className="text-muted">{row.bank_account_no}</small></div>
                    </TextNoWrap>
                )
            },
        },
        {
            title: 'สถานะ',
            render: (row: any) => (
                <div>
                    <ListStatusBadge status={row.status} />
                </div>
            ),
            width: '200px',
        },
        {
            title: 'เวลาทำรายการ',
            render: (row: any) => {
                const colorStatus = row.worker_status && Object.values(statusColor)[Object.keys(statusColor).indexOf(row.worker_status)]
                return (
                    <div>
                        <div>{row.createdAt} </div>
                        <Badge className="d-block badge" bgColor={colorStatus}>{`worker: ${row.worker_status}`}</Badge>
                    </div>
                )
            },
            width: '200px',
        }
    ]

    return (
        <ListTableCard className="p-3 p-md-4 bg-white mb-4">
            <div className="card-head">
                <div className="row mb-md-3 align-items-md-center">
                    <div className="col-6 col-md-auto d-flex align-items-center">
                        <h2>รายการเบิก</h2>
                    </div>
                    <div className="col-6 col-md-auto ms-md-auto mb-3 mb-md-0">
                        <Link
                            to={'/transfer'}
                            className="btn btn-primary"
                        >
                            <span>ดูรายการทั้งหมด</span>
                        </Link>
                    </div>
                </div>
            </div>
            <Table
                columns={columns as any}
                rowKey={record => record.id}
                dataSource={filteredItems}
                loading={loading}
                scroll={{ x: true }}
                pagination={false}
                sticky={{ offsetHeader: 57 }}
            />
        </ListTableCard>
    );
}

export default ListTable

const ListTableCard = styled.div`
    background: #fff;
    border-radius: 16px;
    border-radius: 16px;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

`

const Badge = styled.span<{ bgColor: string }>`
	font-weight: normal;
	color: rgb(${props => props.bgColor});
    background: rgba(${props => props.bgColor}, 0.1);
`

const TextNoWrap = styled.div`
    div, small {
        white-space: nowrap;
    }

    .wrap {
        white-space: break-spaces !important;
    }
`