import React, { FC, useState } from 'react'
import {
    Card,
    Divider,
    Input,
    Form,
    Space,
    Button,
    message,
    notification,
    Result
} from 'antd'
import imageiOS from './../../assets/img/app-store-badge-th.png'
import imageAndroid from './../../assets/img/google-play-badge-th.png'
import { GenQRCode } from '../../utils/QRCodeTransfer'
import SendAPI from '../../utils/SendAPI'

interface SecurityProps {
    otp: string
}
interface ViewDataProps {
    data: any
    loading: boolean
}

const ViewData: FC<ViewDataProps> = ({ data, loading }) => {
    const { sendAPIWithJWT } = SendAPI();
    const [form] = Form.useForm();
    const [btnLoading, setBtnLoading] = useState(false)

    const SecurityOTP = async (values: SecurityProps) => {
        const dataResponse: any = await sendAPIWithJWT({
            path: `/security`,
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            data: values
        });
        if (dataResponse.status === 200) {
            notification.success({
                message: 'Success',
                description: dataResponse.data?.message
            });
            setBtnLoading(false)
            window.location.reload();
        } else {
            // 400
            notification.error({
                message: 'Error',
                description: dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด"
            });
            setBtnLoading(false)
            form.resetFields();
        }
    }

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: SecurityProps) => {
                setBtnLoading(true)
                SecurityOTP(values)
            })
            .catch((errorInfo) => { });
    };

    return (
        <>
            <Card title="2-Factor authentication" loading={loading} >
                {data?.security ?
                    <>
                        <Divider orientation="left" plain>
                            1. ดาวน์โหลดและติดตั้ง Google Authenticator
                        </Divider>
                        <Space>
                            <a href="https://apps.apple.com/th/app/google-authenticator/id388497605" target="_blank" rel="noreferrer">
                                <img src={imageiOS} alt="ios_store" style={{ width: '150px', padding: '7px' }} />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=th&gl=US" target="_blank" rel="noreferrer">
                                <img src={imageAndroid} alt="ios_store" style={{ width: '150px' }} />
                            </a>
                        </Space>
                        <Divider orientation="left" plain>
                            2. เปิดแอปพลิเคชันเพื่อแสกน QR CODE และเก็บรหัสสำรอง
                        </Divider>
                        <p>แสกน QR CODE ในแอปพลิเคชัน Google Authenticator</p>
                        {data?.code?.data_qrcode ? <img src={GenQRCode(data?.code?.data_qrcode)} alt="qrcode" style={{ width: '150px', paddingBottom: '10px' }} /> : '-'}
                        <p>หากคุณไม่สามารถแสกน QR CODE โปรดป้อนคีย์ด้านล่างนี้ด้วยตนเองในแอปพลิเคชัน</p>
                        <Space>
                            <h2 style={{ color: '#0d6efd' }}>{data?.code?.key}</h2>
                            <Button type="link" onClick={() => {
                                navigator.clipboard.writeText(data?.code?.key)
                                message.success('คัดลอกสำเร็จ!');
                            }}>
                                คัดลอก
                            </Button>
                        </Space>
                        <p style={{ color: '#ff0000' }}>โปรดเก็บคีย์นี้ไว้บนกระดาน คีย์นี้จะช่วยให้คุณสามารถกู้คืน 2-Factor authentication ในกรณีที่โทรศัพท์สูญหาย</p>
                        <Divider orientation="left" plain>
                            3. ใส่รหัส 6 หลักที่ได้จากแอปพลิเคชัน
                        </Divider>
                        <Form
                            form={form}
                            name="2faForm"
                            layout="vertical"
                            hideRequiredMark
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 5 }}
                        >
                            <Form.Item
                                name="otp"
                                label="OTP"
                                rules={[{ required: true, message: 'กรุณากรอกรหัส 6 หลักที่ได้จากแอปพลิเคชัน' }]}
                                hasFeedback
                            >
                                <Input placeholder="รหัส 6 หลัก" />
                            </Form.Item>
                            <div className="text-left">
                                <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => handleFormSubmit()}>ยืนยัน</button>
                            </div>
                        </Form>
                    </>
                    : <Result
                        status="success"
                        title="ท่านได้เปิดการใช้งาน 2FA แล้ว"
                        subTitle=""
                        extra={[
                            // <Button type="primary" key="close" onClick={() => message.warning('Coming soon!')}>
                            //     ปิดใช้งาน 2FA
                            // </Button>
                        ]}
                    />
                }
            </Card>
        </>
    )
}
export default ViewData
