import React, { Fragment, useEffect, useState } from 'react'
import ViewSecurity from '../../components/Security/View'
// import UsersMockup from '../../mockup-json/UsersMockup.json'
import SendAPI from '../../utils/SendAPI'
const Security = () => {
    const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)
    const [dataKeySecurity, setDataKeySecurity] = useState<any>([])
    const getKey = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/security",
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataKeySecurity({ security: true, code: dataResponse.data })
        } else {
            setDataKeySecurity({ security: false })
        }
        setLoading(false)
        return dataResponse
    }

    useEffect(() => {
        getKey()
    }, [])// eslint-disable-line
    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <ViewSecurity data={dataKeySecurity} loading={loading} />
            </div>
        </Fragment>
    )
}

export default Security