import React, { useState, useEffect } from 'react'
import TransferSummaryTable from '../../components/TransferSummary/Table'
import SendAPI from '../../utils/SendAPI'
import { DatePicker, Select, notification } from 'antd'
import moment from 'moment'
// import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import 'jspdf-autotable'
import '../../assets/fonts/THSarabunNew-normal';
import styled from 'styled-components'
import Swal from 'sweetalert2'



const TransferSummary = () => {
    const { sendAPIWithJWT } = SendAPI();
    const { Option } = Select;

    const [dataCompanys, setDataCompanys] = useState<any>([])
    const [filterCompany, setFilterCompany] = useState<any>('')
    const [filterStatusWorker, setFilterStatusWorker] = useState('success')
    const { RangePicker } = DatePicker
    const dateFormat = 'DD/MM/YYYY'
    const [sDateShow, setSDateShow] = useState<string>('')
    const [eDateShow, setEDateShow] = useState<string>('')

    const [companyShow, setCompanyShow] = useState<string>('')
    const [dateStartSearch, setDateStartSearch] = useState<any>(moment().startOf('month'))
    const [dateEndSearch, setDateEndSearch] = useState<any>(moment().endOf('month'))

    const [loading, setLoading] = useState(true)
    const [dataTransfers, setDataTransfers] = useState<any>([])
    const [expenseLabels, setExpenseLabels] = useState<any>([])
    const getTransfers = async (dateStart: any, dateEnd: any) => {
        let company_id = ""
        if (filterCompany) {
            company_id = `&company_id=${filterCompany}`
        }
        let url = `/reports/all?worker_status=${filterStatusWorker}${company_id}`
        if (dateStart !== null && dateEnd !== null) {
            const sDate = moment(dateStart).format('YYYY-MM-DD');
            const eDate = moment(dateEnd).format('YYYY-MM-DD');
            url = `${url}&sdate=${sDate}&edate=${eDate}`
        }

        const dataResponse: any = await sendAPIWithJWT({
            path: url,
            method: "get",
        });
        if (dataResponse.status === 200) {
            // console.log(dataResponse);

            setDataTransfers(dataResponse.data?.data !== null ? dataResponse.data?.data : [])
            setExpenseLabels(dataResponse.data?.labels !== null ? dataResponse.data?.labels : [])
        }
        setLoading(false)
        return dataResponse
    }


    const onSearch = () => {
        setLoading(true)
        getTransfers(dateStartSearch, dateEndSearch)
        setSDateShow(moment(dateStartSearch).format('DD/MM/YYYY'))
        setEDateShow(moment(dateEndSearch).format('DD/MM/YYYY'))
    }

    const changeSearchDate = (date: any, dateString: any) => {
        if (date !== null) {
            setDateStartSearch(date[0])
            setDateEndSearch(date[1])
        } else {
            setDateStartSearch(null)
            setDateEndSearch(null)
        }
    }

    const docToPrint: any = React.createRef();

    const printDocument = () => {
        if (dataTransfers.length === 0) {
            notification.warning({
                message: 'Warning',
                description: "ไม่พบรายการ"
            });
            return
        }
        Swal.fire({
            title: 'แจ้งเตือน!',
            text: "คุณต้องการส่งออกข้อมูลนี้ใช่หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ไม่ใช่',
            confirmButtonText: 'ใช่'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let doc: any = new jsPDF('p', 'pt');
                doc.setFontSize(20);
                doc.autoTable({
                    html: '#table-summary',
                    styles: {
                        font: 'THSarabunNew',
                        fontStyle: 'normal',
                    },
                    margin: {
                        bottom: 60
                    }
                })
                doc.save(`Summary_${moment().format('DD/MM/YYYY')}.pdf`)
            }
        })
    };

    const getCompanys = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/companys",
            method: "get",
        });

        if (dataResponse.status === 200) {
            setDataCompanys(dataResponse.data?.data)
        }
        return dataResponse
    }

    useEffect(() => {
        onSearch()
    }, [filterCompany]) // eslint-disable-line 

    useEffect(() => {
        getCompanys()
    }, []) // eslint-disable-line 

    const getCompanyOption = Object.values(dataCompanys).map((item: any) => {
        return <Option key={item.id} value={item.id}>{item.name}</Option>
    })

    return (
        <ListTableCard className="p-3 p-md-4 bg-white my-3 my-md-5">
            <div className="card-head">
                <div className="row mb-md-3 align-items-center">
                    <div className="col-12 col-md-auto mb-3 mb-md-0 mb-md-0">
                        <h2>สรุปค่าใช้จ่าย</h2>
                        {/* <h6>วันที่ <b>{sDateShow}</b> ถึง <b>{eDateShow}</b></h6> */}
                    </div>
                    <div className="col-12 col-md-auto ms-md-auto d-md-flex">
                        <DateFilter>
                            <RangePicker
                                placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                                defaultValue={[moment(moment().startOf('month'), dateFormat), moment(moment().endOf('month'), dateFormat)]}
                                ranges={{
                                    'วันนี้': [moment(), moment()],
                                    'เมื่อวาน': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                    'อาทิตย์นี้': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
                                    'อาทิตย์ที่แล้ว': [moment().startOf('isoWeek').subtract(7, 'days'), moment().endOf('isoWeek').subtract(7, 'days')],
                                    'เดือนนี้': [moment().startOf('month'), moment().endOf('month')],
                                    'เดือนที่แล้ว': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                }}
                                format={dateFormat}
                                onChange={changeSearchDate}
                            />
                            <button type="button" className="btn btn-primary ms-md-3" onClick={onSearch} >ค้นหา</button>
                        </DateFilter>
                        <button type="button" className="btn btn-info ms-md-3 mb-3 mb-md-0"
                            onClick={() => {
                                printDocument()
                            }}>Export PDF</button>
                    </div>
                </div>

                <div className="ms-md-auto filter-wrapper">
                    <div className="filter-group">
                        <label>Company:</label>
                        <Select
                            size="large"
                            dropdownStyle={{ minWidth: "10%" }}
                            value={filterCompany}
                            onSelect={(value: any, text: any) => {
                                setFilterCompany(value)
                                setCompanyShow(text.key ? ` Company : ${text.children}` : '')
                            }}
                        >
                            <Option value="">ทั้งหมด</Option>
                            {getCompanyOption}
                        </Select>
                    </div>
                    <div className="filter-group">
                        <label>Worker:</label>
                        <Select
                            size="large"
                            dropdownStyle={{ minWidth: "10%" }}
                            value={filterStatusWorker}
                            onSelect={(value: any) => {
                                setFilterStatusWorker(value)
                            }}
                        >
                            <Option value="pending">รอดำเนินการ</Option>
                            <Option value="processing">กำลังดำเนินการ</Option>
                            <Option value="verified">ตรวจสอบบัญชีแล้ว</Option>
                            <Option value="success">สำเร็จ</Option>
                            <Option value="error">ไม่สำเร็จ</Option>
                        </Select>
                    </div>
                </div>
            </div>


            <ContentPDF ref={docToPrint}  >
                <TransferSummaryTable
                    list={dataTransfers}
                    expenseLabels={expenseLabels}
                    loading={loading}
                    textHeader={`สรุปค่าใช้จ่าย`}
                    dateHeader={`ระหว่างวันที่ ${sDateShow} - ${eDateShow}`}
                    dateExport={`วันที่ : ${moment().format('DD/MM/YYYY')}`}
                    companyShow={companyShow}
                />
            </ContentPDF>
        </ListTableCard>
    )
}

export default TransferSummary

const ContentPDF = styled.div`

`

const ListTableCard = styled.div`
    background: #fff;
    border-radius: 16px;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

`

const DateFilter = styled.div`
    display: flex;
    margin-bottom: 1rem;

    @media (min-width: 768px) {
        margin-bottom: 0;
    }

    .ant-picker {
        min-width: 254px;
        width: 254px;
        margin-right: 8px;
    }

`