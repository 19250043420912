import React, { FC } from 'react'
import { Modal } from 'reactstrap'
import SCBSlip from './SCBSlip'

interface SlipModalProps {
    data: any
    close: () => void
    isOpen: boolean
}

const SlipModal: FC<SlipModalProps> = ({ close, isOpen, data }) => {

    return (
        <>
            <Modal isOpen={isOpen} className="modal-dialog-centered" fade={true} toggle={() => close()}>
                <div className="modal-body p-lg-4">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h4 className="mb-0"><strong>{`สลิปโอนเงิน : ${data.title}`}</strong></h4>
                        <div className="modal-close">
                            <i className="far fa-times" onClick={() => close()}></i>
                        </div>
                    </div>
                    <SCBSlip data={data?.transaction_log} />
                </div>
            </Modal>
        </>
    )
}
export default SlipModal
