import React, { FC, useEffect, useState } from 'react'
import {
    // Modal,
    Drawer,
    Space,
    Button,
    Form,
    Col,
    Row,
    Input,
    InputNumber,
    Select,
    notification,
    Card,
    Alert
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
// import { Modal } from 'reactstrap'
// import Select from 'react-select'
import SendAPI from '../../utils/SendAPI'

interface CreateTransactionProps {
    accountList: object
    companyList: object
    bankList: object
    amount?: number
    isOpenCreateData: boolean
    closeCreateData: () => void
    onloadData?: () => void

    transaction?: any
}
interface TransactionInsertProps {
    account_id: number
    company_id: number
    title: string
    bank_id: number
    bank_account_no: string
    bank_account_name: string
    amount: number
    remark?: string
}

const CreateTransaction: FC<CreateTransactionProps> = ({ isOpenCreateData, closeCreateData, onloadData, accountList, companyList, bankList, amount, transaction }) => {
    const nameTitle = "เบิกค่าใช้จ่าย"
    const { sendAPIWithJWT } = SendAPI();
    const [form] = Form.useForm();
    const { Option } = Select;
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [showInformationBank, setShowInformationBank] = useState<boolean>(false)
    const [btnLoadingInformationBank, setLoadingInformationBank] = useState<boolean>(false)
    const [bankID, setBankID] = useState<number>(0)
    const [bankAccountNo, setBankAccountNo] = useState<string>('')
    // const [bankAccountNoDuplicate, setBankAccountNoDuplicate] = useState<string>('')
    const [dataInformationBank, setDataInformationBank] = useState<any>({})
    const [dataSelectAccount, setDataSelectAccount] = useState<any>({})
    // const [dataSelectEmployee, setDataSelectEmployee] = useState<any>({})
    const patternNumberBank: any = new RegExp("^([0-9][0-9]*|0)$")
    const patternNumber: any = new RegExp("^([-]?([0-9]+([.][0-9]*)?|[.][0-9]+))$")
    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }


    const transactionCreate = async (values: TransactionInsertProps) => {
        const dataResponse: any = await sendAPIWithJWT({
            path: `/transfers`,
            method: "post",
            headers: { 'Content-Type': 'application/json' },
            data: values
        });
        if (dataResponse.status === 200) {
            notification.success({
                message: 'Success',
                description: dataResponse.data?.message
            });
            setBtnLoading(false)
            closeCreateData()
            if (onloadData) {
                onloadData()
            }
        } else {
            // 400
            notification.error({
                message: 'Error',
                description: dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด"
            });
            setBtnLoading(false)
            if (onloadData) {
                onloadData()
            }
        }
    }

    const handleFormSubmit = () => {
        if (dataInformationBank?.result?.accountToName) {
            form.setFieldsValue({ bank_account_name: dataInformationBank?.result?.accountToName })
            form.validateFields()
                .then((values: TransactionInsertProps) => {
                    setBtnLoading(true)
                    values.amount = Number(values.amount)
                    transactionCreate(values)
                })
                .catch((errorInfo) => { });
        } else {
            notification.error({
                message: 'Error',
                description: "กรุณาตรวจสอบบัญชีธนาคาร"
            });
        }
    };

    const onChangeValueTransaction = (changedFields: any, allFields: any) => {
        setBankAccountNo(allFields.bank_account_no ? allFields.bank_account_no : '')
        setBankID(allFields.bank_id ? allFields.bank_id : 0)
    }

    const clickBankVerify = async () => {
        if (bankID !== 0 && bankAccountNo !== '') {
            setLoadingInformationBank(true)
            const dataResponse: any = await sendAPIWithJWT({
                path: `/banks/verify`,
                method: "post",
                headers: { 'Content-Type': 'application/json' },
                data: {
                    "bank_id": bankID,
                    "bank_account_no": bankAccountNo
                }
            });
            if (dataResponse.status === 200) {
                notification.success({
                    message: 'Success',
                    description: "ตรวจสอบบัญชีสำเร็จ"
                });
                setDataInformationBank(dataResponse.data?.data)
                setShowInformationBank(true)
                setLoadingInformationBank(false)
            } else {
                // 400
                notification.error({
                    message: 'Error',
                    description: dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด"
                });
                setDataInformationBank({})
                setShowInformationBank(false)
                setLoadingInformationBank(false)
            }
        }
    }

    const getAccountOption = Object.values(accountList).map((item: any) => {
        return <Option key={item.id} value={item.id} title={`${item.bank_name} | ${item.bank_account} (คงเหลือ ${numberWithCommas(item.remaining_balance)} บาท)`}>{item.bank_name} | {item.bank_account} (คงเหลือ {numberWithCommas(item.remaining_balance)} บาท)</Option>
    })


    const onChangeAccount = (id_account: any) => {
        setDataSelectAccount(Object.values(accountList).find((item: any) => item.id === id_account))
    }

    const getBankOption = Object.values(bankList).map((item: any) => {
        return <Option key={item.id} value={item.id} title={item.name}>{item.name}</Option>
    })

    const getCompanyOption = Object.values(companyList).map((item: any) => {
        return <Option key={item.id} value={item.id} title={item.name}>{item.name}</Option>
    })

    useEffect(() => {
        if (isOpenCreateData) {
            form.resetFields();
            form.setFieldsValue({ amount: amount })
            setShowInformationBank(false)
            setDataInformationBank({})
            setDataSelectAccount({})

            if (transaction && Object.values(transaction).length > 0) {
                form.setFieldsValue(transaction)
                setBankAccountNo(transaction?.bank_account_no)
                // setBankAccountNoDuplicate(transaction?.bank_account_no)
                setBankID(transaction?.bank_id)
                setShowInformationBank(true)
                setDataInformationBank({
                    result: {
                        accountToName: transaction?.bank_account_name
                    }
                })
            }
        }
        return () => {
            setShowInformationBank(false)
            setDataInformationBank({})
            setDataSelectAccount({})
            setBankAccountNo("")
            // setBankAccountNoDuplicate("")
            setBankID(0)
        }
    }, [form, isOpenCreateData, amount, transaction]); // eslint-disable-line

    // useEffect(() => {
    //     if (bankAccountNoDuplicate === form.getFieldValue('bank_account_no')) {
    //         clickBankVerify()
    //     }
    // }, [form, bankAccountNoDuplicate]); // eslint-disable-line


    return (
        <>
            <Drawer
                title={nameTitle}
                onClose={closeCreateData}
                visible={isOpenCreateData}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        <Button onClick={closeCreateData} size="large">ยกเลิก</Button>
                        <Button
                            onClick={handleFormSubmit}
                            type="primary"
                            loading={btnLoading}
                            size="large"
                        >
                            <i className="far fa-money-bill-wave-alt me-2"></i>
                            เบิกค่าใช้จ่าย
                        </Button>
                    </Space>
                }
            >
                <Form
                    form={form}
                    name="transactionForm"
                    layout="vertical"
                    hideRequiredMark
                    onValuesChange={onChangeValueTransaction}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="company_id"
                                label=" Company"
                                rules={[{ required: true, message: 'กรุณาเลือก Company' }]}
                                hasFeedback
                            >
                                <Select
                                    placeholder="เลือก Company"
                                    showSearch
                                    optionFilterProp="title"
                                    filterOption={(input: any, option: any) => {
                                        return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }}
                                >
                                    {getCompanyOption}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="title"
                                label="ชื่อรายการ"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อรายการ' }]}
                                hasFeedback
                            >
                                <Input placeholder="ชื่อรายการ" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="account_id"
                                label="บัญชีต้นทาง"
                                rules={[{ required: true, message: 'กรุณาเลือกบัญชีต้นทาง' }]}
                                hasFeedback>
                                <Select
                                    placeholder="เลือกบัญชีต้นทาง"
                                    showSearch
                                    optionFilterProp="title"
                                    onChange={(e) => onChangeAccount(e)}
                                    filterOption={(input: any, option: any) => {
                                        return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }}
                                >
                                    {getAccountOption}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="bank_id"
                                label="ธนาคาร"
                                rules={[{ required: true, message: 'กรุณาเลือกธนาคาร' }]}
                                hasFeedback
                            >
                                <Select
                                    placeholder="เลือกธนาคาร"
                                    showSearch
                                    optionFilterProp="title"
                                    filterOption={(input: any, option: any) => {
                                        return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }}
                                >
                                    {getBankOption}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={20}>
                            <Form.Item
                                name="bank_account_no"
                                label="เลขที่บัญชีธนาคาร"
                                rules={[
                                    { required: true, message: 'กรุณากรอกเลขที่บัญชีธนาคาร' },
                                    { pattern: patternNumberBank, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                style={{ minWidth: 'auto' }}
                                hasFeedback >
                                <Input placeholder="เลขที่บัญชีธนาคาร" />
                                {/* 
                               */}
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Button
                                type="primary"
                                className="ms-2 mt-4"
                                style={{ minWidth: '37px', height: '37px' }}
                                shape="circle" icon={<SearchOutlined />}
                                onClick={clickBankVerify}
                                loading={btnLoadingInformationBank}>
                            </Button>
                        </Col>
                    </Row>
                    {/* Object.keys(dataBankVerify).length > 0 */}
                    {showInformationBank && Object.keys(dataInformationBank).length > 0 &&
                        <>
                            <Card title="ข้อมูลบัญชีธนาคาร" size="small" style={{ marginBottom: "10px" }}>
                                <p>ชื่อบัญชี : {dataInformationBank?.result?.accountToName}</p>
                                <p>เลขที่บัญชี : {bankAccountNo}</p>
                                <p>ธนาคาร : {bankID !== 0 ? Object.values(bankList).find((item: any) => item.id === bankID).name : ''}</p>
                            </Card>
                        </>
                    }
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="bank_account_name"
                                label="ชื่อบัญชีธนาคาร"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อบัญชีธนาคาร' }]}
                                hasFeedback
                                style={{ display: 'none' }}
                            >
                                <Input placeholder="ชื่อบัญชีธนาคาร" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="amount"
                                label="จำนวนเงิน (บาท)"
                                rules={[
                                    { required: true, message: 'กรุณากรอกจำนวนเงิน' },
                                    { pattern: patternNumber, message: 'กรุณากรอกเป็นตัวเลขเท่านั้น' }
                                ]}
                                hasFeedback
                                extra="กรุณากรอกเป็นจำนวนเต็มบวกเท่านั้น"
                            >
                                <InputNumber
                                    placeholder="จำนวนเงิน (บาท)"
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                                    controls={false}
                                />
                            </Form.Item>
                            {Object.values(dataSelectAccount).length > 0 && (
                                <Alert message={`จำนวนเงินที่สามารถโอนได้ในวันนี้ ${numberWithCommas(dataSelectAccount.remaining_transfer_today)}`} type="error" />
                            )}
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="remark"
                                label="หมายเหตุ"
                                rules={[{ required: false, message: 'กรุณากรอกหมายเหตุ' }]}
                            >
                                <Input.TextArea rows={3} placeholder="หมายเหตุ" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="text-end">
                        <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => handleFormSubmit()}><i className="far fa-money-bill-wave-alt me-2"></i> เบิกค่าใช้จ่าย</button>
                        <button type="button" className="btn btn-light me-3" onClick={() => closeCreateData()}>ยกเลิก</button>
                    </div>
                </Form>
            </Drawer>
        </>
    )
}
export default CreateTransaction