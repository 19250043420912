import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    Rate,
    Drawer,
    Space,
    Button,
    Table,
    Typography
} from 'antd'
// import ListStatusBadge from '../Elements/ListStatusBadge'
import ScrollToTopPagination from '../../utils/ScrollToTopPagination'
import CreateTransactionFav from './CreateTransactionFav'
// import { SearchOutlined } from '@ant-design/icons'
// import { Modal } from 'reactstrap'
import Swal from 'sweetalert2'
import SendAPI from '../../utils/SendAPI'
// import useAuth from '../../utils/Auth_'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'

interface TransactionFavProps {
    favList: any
    accountList: object
    companyList: object
    bankList: object
    isOpenCreateData: boolean
    closeCreateData: () => void
    onloadData?: () => void
}


const TransactionFav: FC<TransactionFavProps> = ({ isOpenCreateData, closeCreateData, onloadData, favList, accountList, companyList, bankList }) => {
    const nameTitle = "รายการโปรด"

    const { sendAPIWithJWT } = SendAPI();
    const [createTransactionModal, setCreateTransactionModal] = useState(false)
    const [transaction, setTransaction] = useState<Object>({})
    const [dataOjects, setDataOjects] = useState<any>([])
    const toggle = () => setCreateTransactionModal(!createTransactionModal)
    const handleData = (object: any) => setTransaction(object)
    // const { getMe } = useAuth();

    const { data: getMe } = useSelector((state: ApplicationState) => state.auth)

    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }

    // const statusColor: any = {
    //     'error': '255, 0, 0',
    //     'pending': '160, 160, 160',
    //     'processing': '24, 144, 255',
    //     'success': '19, 155, 130',
    //     'verified': '24, 144, 255',
    // }

    // const workerStatusTH: any = {
    //     'error': 'ไม่สำเร็จ',
    //     'pending': 'รอดำเนินการ',
    //     'processing': 'กำลังดำเนินการ',
    //     'verified': 'ตรวจสอบบัญชีแล้ว',
    //     'success': 'สำเร็จ',
    // }

    const handleFavorite = async (data: any) => {

        const dataResponse: any = await sendAPIWithJWT({
            path: `/transfers/${data.id}/favorites`,
            method: data.favorite === "actived" ? "delete" : "post",
        });
        if (dataResponse.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'แจ้งเตือน',
                html: dataResponse.data?.message,
                timer: 2000,
                timerProgressBar: true
            })
            // favList[favList.findIndex((el: any) => el.id === data.id)].favorite = dataResponse.data?.data.favorite
            // setDataOjects(favList)
            onloadData && onloadData()
        } else {
            Swal.fire('แจ้งเตือน!', dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด", 'error')
        }
    };

    const columns = [
        {
            title: '#',
            render: (row: any) => {
                return (
                    <>
                        <div>{row.serial}</div>
                        <small className="text-muted">{`#${row.id}`}</small>
                    </>
                )
            },
            width: '72px',
            defaultSortOrder: 'ascend',
            sorter: (a: any, b: any) => a.serial - b.serial,
        },
        {
            title: '',
            render: (row: any) => {
                const Fav = row.favorite === "actived" ? 1 : 0
                const FavDisable = getMe.id === row.created_user_id ? false : true
                const FavTooltips = getMe.id === row.created_user_id ? 'รายการโปรด' : 'ไม่สามารถเพิ่มรายการโปรดได้ เนื่องจากไม่ใช่รายการโอนเงินของคุณ'
                return (
                    <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
                        <Rate count={1} defaultValue={Fav} tooltips={[FavTooltips]} onChange={() => handleFavorite(row)} disabled={FavDisable} />
                    </Space>
                )
            }
        },
        {
            title: 'ชื่อรายการ',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        {row.title}
                        {row.remark &&
                            <Typography.Paragraph ellipsis={{ rows: 4, expandable: true, symbol: "เพิ่มเติม" }}>
                                <small className="text-muted wrap">{`* ${row.remark}`}</small>
                            </Typography.Paragraph>
                        }
                    </TextNoWrap>
                )
            },
            width: '180px',
        },
        {
            title: ' Company',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div style={{ minWidth: '64px' }}>{row.company_name}</div>
                    </TextNoWrap>
                )
            },
        },
        {
            title: 'จำนวนเงิน',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div>฿ <strong>{numberWithCommas(row.amount)}</strong></div>
                    </TextNoWrap>
                )
            },
            width: '120px',
        },
        {
            title: 'โอนจาก',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div>{row.account_bank_name}</div>
                        <small className="text-muted">{row.account_bank_account}</small>
                    </TextNoWrap>
                )
            },
            width: '160px',
        },
        {
            title: 'ไปยัง',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div>{row.bank_account_name}</div>
                        <div><small className="text-muted">{row.bank_name}</small></div>
                        <div><small className="text-muted">{row.bank_account_no}</small></div>
                    </TextNoWrap>
                )
            },
            width: '180px',
        },
        // {
        //     title: 'สถานะ',
        //     render: (row: any) => {
        //         return (
        //             <ListStatusBadge status={row.status} />
        //         )
        //     },
        //     width: '120px',
        // },
        // {
        //     title: 'เวลาทำรายการ',
        //     render: (row: any) => {
        //         const colorStatus = row.worker_status && Object.values(statusColor)[Object.keys(statusColor).indexOf(row.worker_status)]
        //         return (
        //             <>
        //                 <div>{row.createdAt}</div>
        //                 <Badge className="d-block badge" bgColor={colorStatus}>{`worker: ${row.worker_status && Object.values(workerStatusTH)[Object.keys(workerStatusTH).indexOf(row.worker_status)]}`}</Badge>
        //             </>
        //         )
        //     },
        // },
        {
            title: 'จัดการ',
            render: (row: any) => {
                return (
                    <div>
                        <Space size={0}>
                            <button className="btn btn-warning btn-sm text-white me-2" onClick={() => { handleData(row); toggle(); }}>ทำซ้ำ</button>
                        </Space>
                    </div>
                )
            },
            width: '220px',
        }
    ]

    const onCloseDrawer = () => {
        closeCreateData()
        onloadData && onloadData()
    }
    useEffect(() => {
        if (isOpenCreateData) {
            favList.forEach((item: any, index: number) => { item.serial = index + 1; });
            setDataOjects(favList)
        }
    }, [isOpenCreateData, favList]);

    // 2812633910
    return (
        <>
            <Drawer
                className="drawerSalary"
                title={nameTitle}
                onClose={onCloseDrawer}
                visible={isOpenCreateData}
                bodyStyle={{ paddingBottom: 80 }}
                width={global.window.innerWidth <= 1200 ? global.window.innerWidth : global.window.innerWidth * .6}
                extra={
                    <Space>
                        <Button onClick={onCloseDrawer} size="large">ปิด</Button>
                    </Space>
                }
            >
                <ListTableCard className="p-3 p-md-4 bg-white mb-4">
                    <Table
                        columns={columns as any}
                        dataSource={dataOjects}
                        rowKey={record => record.id}
                        scroll={{ x: true }}
                        sticky={{ offsetHeader: 57 }}
                        onChange={ScrollToTopPagination}
                        expandable={{ columnWidth: '100px' }}
                        pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '30', '40', '50', '80', '100'],
                            position: ['topRight', 'bottomRight'],
                            locale: { items_per_page: " รายการ" }
                        }}
                    />
                </ListTableCard>
                <CreateTransactionFav
                    accountList={accountList as any}
                    companyList={companyList as any}
                    bankList={bankList as any}
                    isOpenCreateData={createTransactionModal}
                    closeCreateData={() => toggle()}
                    onloadData={onloadData}
                    transaction={transaction as any}
                />
            </Drawer>
        </>
    )
}
export default TransactionFav


const ListTableCard = styled.div`
    background: #fff;
    border-radius: 16px;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

    .ant-picker {
        min-width: 254px;
        width: 254px;
        margin-right: 8px;
    }
`
// const Badge = styled.span<{ bgColor: string }>`
// 	font-weight: normal;
// 	color: rgb(${props => props.bgColor});
//     background: rgba(${props => props.bgColor}, 0.1);
// `

const TextNoWrap = styled.div`
    div, small {
        white-space: nowrap;
    }

    .wrap {
        white-space: break-spaces !important;
    }
`
