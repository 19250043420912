import React, { Fragment, useEffect, useState } from 'react'
import TransferTable from '../../components/Transfer/TransferTable'
// import ListMockup from '../../mockup-json/ListMockup.json'
// import EmployeesMockup from '../../mockup-json/EmployeesMockup.json'
import SendAPI from '../../utils/SendAPI'
import moment from 'moment'
import useSWR from 'swr'

const useFetchTransfer = (params: string, refreshInterval: number, dataParams: any) => {
    const { sendAPIWithJWT } = SendAPI();
    const { data, mutate, error } = useSWR([`/transfers${params}`, dataParams.page, dataParams.limit], async () =>
        await sendAPIWithJWT({
            path: `/transfers?page=${dataParams.page}&limit=${dataParams.limit}${params}`,
            method: "get"
        })
        , { refreshInterval }
    )

    return {
        data,
        isLoading: !error && !data,
        isError: error,

        mutate,
    }
}

const Transfer = () => {

    const { sendAPIWithJWT } = SendAPI();
    const [pagination, setPagination] = useState<any>({ page: 1, pageSize: 25 })

    // const [filterText, setFilterText] = useState('')
    const [filterStatus, setFilterStatus] = useState('')
    const [filterStatusWorker, setFilterStatusWorker] = useState('')
    const [filterCompany, setFilterCompany] = useState('')
    // const [loading, setLoading] = useState(true)

    // const [dateStartSearch, setDateStartSearch] = useState<any>(moment().startOf('isoWeek'))
    // const [dateEndSearch, setDateEndSearch] = useState<any>(moment().endOf('isoWeek'))
    const [dateStartSearch, setDateStartSearch] = useState<any>()
    const [dateEndSearch, setDateEndSearch] = useState<any>()

    const [dateTransfer, setDateTransfer] = useState<string>("")

    const [dataTransfers, setDataTransfers] = useState<any>([])
    const [dataTransfersFav, setDataTransfersFav] = useState<any>([])
    const [dataAccounts, setDataAccounts] = useState<any>([])
    const [dataCompanys, setDataCompanys] = useState<any>([])
    const [dataBanks, setDataBanks] = useState<any>([])
    // params?: PaginationParams


    const { data, isLoading, mutate } = useFetchTransfer(dateTransfer, 3000, { page: pagination.page, limit: pagination.pageSize })

    // const getTransfers = async (dateStart: any, dateEnd: any) => {
    //     let date = ""
    //     if (dateStart && dateEnd) {
    //         const sDate = moment(dateStart).format('YYYY-MM-DD');
    //         const eDate = moment(dateEnd).format('YYYY-MM-DD');
    //         date = `?sdate=${sDate}&edate=${eDate}`
    //     }


    //     const dataResponse: any = await sendAPIWithJWT({
    //         path: `/transfers${date}`,
    //         method: "get",
    //     });
    //     if (dataResponse.status === 200) {
    //         setDataTransfers(dataResponse.data?.data)
    //     }
    //     setLoading(false)
    //     return dataResponse
    // }

    const getTransfersFav = async () => {

        const dataResponse: any = await sendAPIWithJWT({
            path: `/transfers/favorites`,
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataTransfersFav(dataResponse.data?.data)
        }
        // setLoading(false)
        return dataResponse
    }


    const getAccounts = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/accounts?status=enable",
            method: "get",
        });
        if (dataResponse.status === 200) {
            setDataAccounts(dataResponse.data?.data)
        }
        // setLoading(false)
        return dataResponse
    }

    const getCompanys = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/companys",
            method: "get",
        });

        if (dataResponse.status === 200) {
            setDataCompanys(dataResponse.data?.data)
        }
        return dataResponse
    }

    const getBanks = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path: "/banks",
            method: "get",
        });

        if (dataResponse.status === 200) {
            setDataBanks(dataResponse.data?.data)
        }
        return dataResponse
    }

    const changeSearchDate = (date: any) => {
        if (date !== null) {
            setDateStartSearch(date[0])
            setDateEndSearch(date[1])
        } else {
            setDateStartSearch(null)
            setDateEndSearch(null)
        }
    }

    const onSearch = () => {
        // setLoading(true)
        // getTransfers(dateStartSearch, dateEndSearch)
        let date = ""
        if (dateStartSearch && dateEndSearch) {
            const sDate = moment(dateStartSearch).format('YYYY-MM-DD');
            const eDate = moment(dateEndSearch).format('YYYY-MM-DD');
            date = `&sdate=${sDate}&edate=${eDate}`
        }

        if (filterStatus ||
            filterStatusWorker ||
            filterCompany) {
            date = `${date}&status=${filterStatus}&company_id=${filterCompany}&worker_status=${filterStatusWorker}`
        }
        setDateTransfer(date)
        getTransfersFav()
    }


    useEffect(() => {
        if (data !== undefined) {
            if (data?.status === 200) {
                setDataTransfers(data.data)
            } else {
                setDataTransfers([])
            }
        }
    }, [data, isLoading])

    useEffect(() => {
        mutate()
    }, [setDateTransfer])// eslint-disable-line

    useEffect(() => {
        onSearch()
        getAccounts()
        getCompanys()
        getBanks()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <TransferTable
                    list={dataTransfers.data || []}
                    listFav={dataTransfersFav}
                    companys={dataCompanys}
                    banks={dataBanks}
                    accounts={dataAccounts}
                    loading={isLoading}
                    onloadData={onSearch}
                    onDateSearch={(e) => changeSearchDate(e)}
                    totalCount={dataTransfers.pagination?.rows || 0}
                    currentPage={pagination.page}
                    onChangePagination={setPagination}
                    // filterText={filterText}
                    filterStatus={filterStatus}
                    filterStatusWorker={filterStatusWorker}
                    filterCompany={filterCompany}
                    // setFilterText={setFilterText}
                    setFilterStatus={setFilterStatus}
                    setFilterStatusWorker={setFilterStatusWorker}
                    setFilterCompany={setFilterCompany}
                />
            </div>
        </Fragment>
    )
}

export default Transfer