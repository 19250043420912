import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { UncontrolledCollapse } from 'reactstrap'
import logo from './../../assets/img/logo.png'
import './../../assets/scss/fontawesome.scss'
import './../../assets/scss/sidebar.scss'
// import useAuth from '../../utils/Auth_'
// import ls from 'localstorage-slim'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'

const Sidebar = () => {
  const { allows } = useSelector((state: ApplicationState) => state.auth)
  const currentLocation = useLocation().pathname;
  return (
    <>
      <div className="sidebar">
        <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto">
          <img src={logo} className="sidebar-logo" alt="logo Expense" />
        </a>
        <ul className="nav nav-pills flex-column mb-auto">
          {Object.values(allows).find((item: any) => item === "DASHBOARD_PAGE") &&
            <li className="nav-item">
              <Link to="/dashboard"
                className={"nav-link link-dark" + (currentLocation === '/dashboard' ? ' active' : '')}
              >
                <i className="fas fa-tachometer-fast"></i>
                <span>ภาพรวม</span>
              </Link>
            </li>
          }
          {Object.values(allows).find((item: any) => item === "TRANSFER_PAGE") &&
            <li className="nav-item">
              <Link to="/transfer"
                className={"nav-link link-dark" + (currentLocation === '/transfer' ? ' active' : '')}
              >
                <i className="far fa-exchange"></i>
                <span>เบิกค่าใช้จ่าย</span>
              </Link>
            </li>
          }
          {Object.values(allows).find((item: any) => item === "REPORT_PAGE") &&
            <li className="nav-item">
              <Link to="#"
                id="reportNavLink"
                className={"nav-link link-dark" + (
                  currentLocation === '/summary' || currentLocation === '/enumerate' ? ' active' : ''
                )}
              >
                <i className="fad fa-file-alt"></i>
                <span>รายงาน</span>
                <i className="far fa-chevron-down"></i>
              </Link>
              <UncontrolledCollapse className="nav-sub-item" toggler="#reportNavLink" defaultOpen={(currentLocation === '/summary' || currentLocation === '/enumerate') ? true : false}>
                <Link to="/summary"
                  className={"nav-sub-link" + (currentLocation === '/summary' ? ' active' : '')}
                >
                  <span>สรุปค่าใช้จ่าย</span>
                </Link>
                <Link to="/enumerate"
                  className={"nav-sub-link" + (currentLocation === '/enumerate' ? ' active' : '')}
                >
                  <span>สรุปค่าใช้จ่ายแจกแจง</span>
                </Link>
              </UncontrolledCollapse>
            </li>
          }
          {Object.values(allows).find((item: any) => item === "ACCOUNT_PAGE") &&
            <li>
              <Link to="/accounts"
                className={"nav-link link-dark" + (currentLocation === '/accounts' ? ' active' : '')}
              >
                <i className="far fa-university"></i>
                <span>บัญชีธนาคาร</span>
              </Link>
            </li>
          }
          {Object.values(allows).find((item: any) => item === "USER_PAGE") &&
            <li>
              <Link to="/users"
                className={"nav-link link-dark" + (currentLocation === '/users' ? ' active' : '')}
              >
                <i className="fas fa-user"></i>
                <span>ผู้ใช้งาน</span>
              </Link>
            </li>
          }
          {Object.values(allows).find((item: any) => item === "BANK_PAGE" || item === "COMPANY_PAGE") &&
            <li className="nav-item">
              <Link to="#"
                id="settingNavLink"
                className={"nav-link link-dark" + (
                  currentLocation === '/setting-banks' || currentLocation === '/setting-companys' ? ' active' : ''
                )}
              >
                <i className="fad fa-cog"></i>
                <span>ตั้งค่า</span>
                <i className="far fa-chevron-down"></i>
              </Link>
              <UncontrolledCollapse className="nav-sub-item" toggler="#settingNavLink" defaultOpen={(currentLocation === '/setting-banks' || currentLocation === '/setting-companys') ? true : false}>
                {Object.values(allows).find((item: any) => item === "BANK_PAGE") &&
                  <Link
                    to="/setting-banks"
                    className={"nav-sub-link" + (currentLocation === '/setting-banks' ? ' active' : '')}
                  >
                    <span>ธนาคาร</span>
                  </Link>
                }
                {Object.values(allows).find((item: any) => item === "COMPANY_PAGE") &&
                  <Link
                    to="/setting-companys"
                    className={"nav-sub-link" + (currentLocation === '/setting-companys' ? ' active' : '')}
                  >
                    <span>Company</span>
                  </Link>
                }
              </UncontrolledCollapse>
            </li>
          }
        </ul>
        <div className="nav-pills">
          <a href="/logout" className="nav-link link-dark">
            <i className="fas fa-sign-out"></i>
            <span>ออกจากระบบ</span>
          </a>
        </div>
      </div>
    </>
  );
}

export default Sidebar;