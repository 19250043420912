import React, { FC } from 'react'
import styled from 'styled-components'
import { Skeleton, Empty } from 'antd';

interface TableProps {
    list: any
    expenseLabels: any
    loading: boolean
    textHeader: string
    dateHeader: string
    dateExport: string
    companyShow: string
}

const Table: FC<TableProps> = ({ list, expenseLabels, loading, textHeader, dateHeader, dateExport, companyShow }) => {
    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }

    return (
        <>
            <TableWrapper className="table-responsive">
                {loading ?
                    <Skeleton active />
                    :
                    <>
                        {Object.values(list).length > 0 ?
                            <table className="table table-striped mt-4" id="table-summary">
                                <thead>
                                    <tr key={'main-1'} >
                                        <th key={'th-no1'} align="center" style={{ textAlign: 'center' }} colSpan={Object.values(expenseLabels).length + 4}>{textHeader}</th>
                                    </tr>
                                    <tr key={'main-2'} >
                                        <th key={'th-no2'} align="center" style={{ textAlign: 'center' }} colSpan={Object.values(expenseLabels).length + 4}>{dateHeader}</th>
                                    </tr>
                                    <tr key={'main-3'} >
                                        <th key={'th-no3-1'} align="left" style={{ textAlign: 'left' }} colSpan={(Object.values(expenseLabels).length + 4) / 2}>{companyShow}</th>
                                        <th key={'th-no3-2'} align="right" style={{ textAlign: 'right' }} colSpan={(Object.values(expenseLabels).length + 4) / 2}>{dateExport}</th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr key={'tr-1'}>
                                        <th key={'th-no'} align="center" style={{ textAlign: 'center' }}>#</th>
                                        <th key={'th-name'} align="left" style={{ textAlign: 'left' }}>Company</th>
                                        <th key={'th-title'} align="left" style={{ textAlign: 'left' }}>ชื่อรายการ</th>
                                        <th key={'th-remark'} align="left" style={{ textAlign: 'left' }}>หมายเหตุ</th>
                                        {Object.values(expenseLabels).map((data: any, index: number) => (
                                            <th key={`th-${data.sequence}`} align="right" style={{ textAlign: 'right' }}>{data.label_name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.values(list).map((data: any, index: number) => (
                                        <tr key={`tr-2-${index}`}>
                                            <td key={`id-${data.company_id}`} align="center" style={{ textAlign: 'center' }}>{index + 1}</td>
                                            <td key={`name-${data.company_id}`}>{data.company_name}</td>
                                            <td key={`title-${data.company_id}`}>{data.title}</td>
                                            <td key={`remark-${data.company_id}`}>{data.remark}</td>
                                            {Object.values(data.labels).map((el: any, index_el: number) => (
                                                <td key={`el-${index_el}-${el.sequence}`} align="right" style={{ textAlign: 'right' }}>{numberWithCommas(el.amount)}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>

                                <tbody>
                                    <TableSummary key={'tr-3'}>
                                        <td key={'sum'} align="right" colSpan={4}>รวม</td>
                                        {Object.values(expenseLabels).map((el: any, index: number) => (
                                            <th key={`sum-${el.sequence}`} align="right" style={{ textAlign: 'right' }}>{numberWithCommas(el.total_amount)}</th>
                                        ))}
                                    </TableSummary>
                                </tbody>
                            </table>
                            :
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </>
                }
            </TableWrapper>
        </>
    );
}

export default Table

const TableWrapper = styled.div`
    table > :not(:first-child) {
        border-top: 0;
    }

    thead {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        background: #fff;
        white-space: nowrap;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: #ccc;
        }
    }
`

const TableSummary = styled.tr`
    background: #eee;
    font-weight: bold;
`