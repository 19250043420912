import React, { FC } from 'react'
import styled from 'styled-components'
import { Skeleton, Empty } from 'antd'

interface ListCardProps {
   list: object
   title: string
   color: string
   symbol?: string
   loading: boolean
}

const ListCard: FC<ListCardProps> = ({ list, title, color, symbol, loading }) => {
    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }

    return (
       <Card titleColor={color ?? '#000'} className="mb-4">
            <div className="card-head">
                <h2>{title}</h2>
            </div>
            <div className="card-body">
                { loading
                    ? <Skeleton active />
                    : <>
                        { Object.values(list).length > 0 
                            ? <>
                                { list && Object.values(list).map((value, key) => (
                                    <List
                                        className="d-flex"
                                        key={key}
                                        textColor={color ?? '#000'}

                                    >
                                        <div className="info">
                                            <h3>{value.account.bank_name}</h3>
                                            <p className="text-muted">{value.account.bank_account}</p>
                                            <p className="text-muted">{value.txn_remark}</p>
                                        </div>
                                        <div className="ms-auto text-end" style={{ minWidth: '135px' }}>
                                            <h3 className="amount">{symbol ? `${symbol} ` : ''}฿ {numberWithCommas(value.txn_amount)}</h3>
                                            <p>{value.txn_date_time}</p>
                                            <span className="badge bg-info">{value.txn_channel}</span>
                                        </div>
                                    </List>
                                ))}
                            </>
                        : <>
                            <Empty style={{marginTop: '10%'}} image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                        </>
                        }
                    </>
                }
            </div>
       </Card>
    )
}

export default ListCard

const Card = styled.div<{titleColor: string}>`
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.02);
    border-radius: 16px;
    overflow: hidden;

    .card-head {
        padding: 16px 24px 8px;

        h2 {
            color: ${props => props.titleColor};
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 0;
        }

        @media only screen and (min-width: 768px) {
            padding: 24px 24px 16px;
        }
    }

    .card-body {
        height: 360px;
        padding: 0 8px 8px;
        overflow-y: scroll;

        h2 {
            margin-bottom: 0;
        }

        @media only screen and (min-width: 768px) {
            height: 480px;
            padding: 0 16px 16px;
        }
    }
`;

const List = styled.div<{textColor: string}>`
    padding: 16px;

    &:nth-of-type(2n) {
        background: #F8F9FB;
    }

    h3 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 4px;
    }

    p {
        font-size: 14px;
        margin-bottom: 0;
        color: rgba(0, 0, 0, 0.5);
    }

    .amount {
        color: ${props => props.textColor};
    }'
`;