import React, { FC } from 'react'
import styled from 'styled-components'

interface ListStatusBadgeProps {
	status: string
}

const ListStatusBadge: FC<ListStatusBadgeProps> = ({ status }) => {
	switch (status) {
		case 'pending': {
			return <Badge bgColor='255, 144, 0' className="d-block badge">รออนุมัติ</Badge>
		}
		case 'approved': {
			return <Badge bgColor='19, 155, 130' className="d-block badge">อนุมัติ</Badge>
		}
		case 'reject': {
			return <Badge bgColor='233, 75, 74' className="d-block badge">ไม่อนุมัติ</Badge>
		}
		default: {
			return <Badge bgColor='0, 0, 0' className="d-block badge">{status}</Badge>
		}
	}
}

export default ListStatusBadge

const Badge = styled.span<{bgColor: string}>`
	font-size: 16px;
	font-weight: normal;
	color: rgb(${props => props.bgColor});
    background: rgba(${props => props.bgColor}, 0.1);
`