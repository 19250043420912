import React, { FC } from 'react'

interface MultipleDataProps {
	name: string
    dateTime: string
    wrap?: boolean
}

const MultipleData: FC<MultipleDataProps> = ({ name, dateTime, wrap }) => {
	return (
        <div>
            <div>{name}</div>
            {dateTime && <small className={'text-muted ' + (wrap ? 'wrap' : '') }>{dateTime}</small>}
        </div>
    )
}

export default MultipleData