import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
// import { Link } from 'react-router-dom'
// import DataTable from 'react-data-table-component';
import CreateTransaction from './CreateTransaction'
import TransactionFav from './TransactionFav'
import ViewTransfer from './ViewTransfer'
import SlipModal from './SlipModal'
import ListStatusBadge from '../Elements/ListStatusBadge'
import { Input, Table, Space, Select, DatePicker, Rate, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2'
import SendAPI from '../../utils/SendAPI'
import ScrollToTopPagination from '../../utils/ScrollToTopPagination'
// import useAuth from '../../utils/Auth_'
import moment from 'moment'
import { nanoid } from 'nanoid'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'

import Fuse from 'fuse.js'
interface TransferTableProps {
    list: any
    listFav: any
    companys: any
    banks: any
    accounts: any
    loading: boolean
    onloadData: () => void
    onDateSearch: (e: any) => void
    currentPage: number
    totalCount?: number
    onChangePagination: (e: any) => void
    // filterText?: string
    filterStatus?: string
    filterStatusWorker?: string
    filterCompany?: string
    // setFilterText: (e: any) => void
    setFilterStatus: (e: any) => void
    setFilterStatusWorker: (e: any) => void
    setFilterCompany: (e: any) => void
}
const TransferTable: FC<TransferTableProps> = ({ list, listFav, companys, banks, accounts, loading, onloadData, onDateSearch, currentPage, totalCount = 0, onChangePagination,
    // filterText,
    filterStatus,
    filterStatusWorker,
    filterCompany,
    // setFilterText,
    setFilterStatus,
    setFilterStatusWorker,
    setFilterCompany }) => {
    const { RangePicker } = DatePicker
    const dateFormat = 'DD/MM/YYYY'

    const { sendAPIWithJWT } = SendAPI();

    const [dataOjects, setDataOjects] = useState<any>([])
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [filterText, setFilterText] = useState<string>('')

    const statusColor: any = {
        'error': '255, 0, 0',
        'pending': '160, 160, 160',
        'processing': '24, 144, 255',
        'success': '19, 155, 130',
        'verified': '24, 144, 255',
    }

    const workerStatusTH: any = {
        'error': 'ไม่สำเร็จ',
        'pending': 'รอดำเนินการ',
        'processing': 'กำลังดำเนินการ',
        'verified': 'ตรวจสอบบัญชีแล้ว',
        'success': 'สำเร็จ',
    }

    const numberWithCommas = (num: any) => {
        num = num.toFixed(2).toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(num))
            num = num.replace(pattern, "$1,$2");
        return num;
    }

    const [createTransactionModal, setCreateTransactionModal] = useState(false)
    const [viewDataDrawer, setViewDataDrawer] = useState(false)
    const [viewSlipModal, setViewSlipModal] = useState(false)
    const [transactionFav, setTransactionFav] = useState(false)
    const [transaction, setTransaction] = useState('')
    const toggle = () => setCreateTransactionModal(!createTransactionModal)
    const toggleViewData = () => setViewDataDrawer(!viewDataDrawer)
    const toggleViewSlip = () => setViewSlipModal(!viewSlipModal)
    const handleViewData = (object: any) => setTransaction(object)
    const toggleFavorite = () => setTransactionFav(!transactionFav)
    const { Option } = Select;


    const [duplicateTransaction, setDuplicateTransaction] = useState<Object>({})
    const handleData = (object: any) => setDuplicateTransaction(object)


    // const { getMe } = useAuth();

    const { data: getMe } = useSelector((state: ApplicationState) => state.auth)


    const handleStatusData = async (status: string, id: number, option?: string) => {
        let statusTitle = (status === 'approved') ? "อนุมัติ" : "ยกเลิก"
        Swal.fire({
            title: 'แจ้งเตือน!',
            text: `คุณต้องการ${statusTitle}รายการนี้ใช่หรือไม่`,
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.isConfirmed && status && id) {
                setBtnLoading(true)
                const dataResponse: any = await sendAPIWithJWT({
                    path: `/transfers/${id}`,
                    method: "patch",
                    headers: { 'Content-Type': 'application/json' },
                    data: {
                        "status": status,
                        "option": option
                    }
                });
                if (dataResponse.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'แจ้งเตือน',
                        html: dataResponse.data?.message,
                        timer: 2000,
                        timerProgressBar: true
                    })
                    list[list.findIndex((el: any) => el.id === id)].status = dataResponse.data?.data.status
                    list[list.findIndex((el: any) => el.id === id)].worker_status = dataResponse.data?.data.worker_status
                } else {
                    Swal.fire('แจ้งเตือน!', dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด", 'error')
                }
                setBtnLoading(false)
            }
        })
    };

    const handleFavorite = async (data: any) => {

        const dataResponse: any = await sendAPIWithJWT({
            path: `/transfers/${data.id}/favorites`,
            method: data.favorite === "actived" ? "delete" : "post",
        });
        if (dataResponse.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'แจ้งเตือน',
                html: dataResponse.data?.message,
                timer: 2000,
                timerProgressBar: true
            })
            // list[list.findIndex((el: any) => el.id === data.id)].favorite = dataResponse.data?.data.favorite
            // setDataOjects(list)
            onloadData && onloadData()
        } else {
            Swal.fire('แจ้งเตือน!', dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด", 'error')
        }
    };

    const changeCompany = (value: number, id: number) => {
        Swal.fire({
            title: 'แจ้งเตือน!',
            text: `คุณต้องการ Company รายการนี้ใช่หรือไม่`,
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const dataResponse: any = await sendAPIWithJWT({
                    path: `/transfers/${id}/company`,
                    method: "patch",
                    headers: { 'Content-Type': 'application/json' },
                    data: {
                        "company": value
                    }
                });
                if (dataResponse.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'แจ้งเตือน',
                        html: dataResponse.data?.message,
                        timer: 2000,
                        timerProgressBar: true
                    })
                    onloadData && onloadData()
                } else {
                    Swal.fire('แจ้งเตือน!', dataResponse.message ? dataResponse.message : "เกิดข้อผิดพลาด", 'error')
                }
            }
        })
    }

    const columns = [
        {
            title: '#',
            render: (row: any) => {
                return (
                    <>
                        {/* <div>{row.serial}</div> */}
                        <div>{`#${row.id}`}</div>
                        {/* <small className="text-muted">{`#${row.id}`}</small> */}
                    </>
                )
            },
            width: '72px',
            defaultSortOrder: 'ascend',
            sorter: (a: any, b: any) => a.serial - b.serial,
        },
        {
            title: '',
            render: (row: any) => {
                const Fav = getMe.id === row.created_user_id ? (row.favorite === "actived" ? 1 : 0) : 0
                const FavDisable = getMe.id === row.created_user_id ? false : true
                const FavTooltips = getMe.id === row.created_user_id ? 'รายการโปรด' : 'ไม่สามารถเพิ่มรายการโปรดได้ เนื่องจากไม่ใช่รายการโอนเงินของคุณ'
                return (
                    <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
                        <Rate count={1} value={Fav} tooltips={[FavTooltips]} onChange={() => handleFavorite(row)} disabled={FavDisable} />
                    </Space>
                )
            }
        },
        {
            title: 'ชื่อรายการ',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div
                            className="btn-link-view"
                            onClick={() => {
                                handleViewData(row)
                                toggleViewData()
                            }}
                        >
                            {row.title}
                        </div>
                        {row.remark &&
                            <Typography.Paragraph ellipsis={{ rows: 4, expandable: true, symbol: "เพิ่มเติม" }}>
                                <small className="text-muted wrap">{`* ${row.remark}`}</small>
                            </Typography.Paragraph>
                        }
                    </TextNoWrap>
                )
            },
            width: '180px',
        },
        {
            title: ' Company',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        {getMe.role === "master" ? (
                            <>
                                <Select
                                    size="large"
                                    dropdownStyle={{ minWidth: "10%" }}
                                    value={row.company_name}
                                    onChange={(value: any) => changeCompany(value, row.id)}
                                >
                                    {getCompanyOption}
                                </Select>
                            </>) : (
                            <div
                                className="btn-link-view"
                                style={{ minWidth: '64px' }}
                                onClick={() => {
                                    handleViewData(row)
                                    toggleViewData()
                                }}
                            >
                                {row.company_name}
                            </div>
                        )}
                    </TextNoWrap >
                )
            },
            width: '180px',
        },
        {
            title: 'จำนวนเงิน',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div>฿ <strong>{numberWithCommas(row.amount)}</strong></div>
                    </TextNoWrap>
                )
            },
            width: '120px',
        },
        {
            title: 'โอนจาก',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div>{row.account_bank_name}</div>
                        <small className="text-muted">{row.account_bank_account}</small>
                    </TextNoWrap>
                )
            },
            width: '160px',
        },
        {
            title: 'ไปยัง',
            render: (row: any) => {
                return (
                    <TextNoWrap>
                        <div>{row.bank_account_name}</div>
                        <div><small className="text-muted">{row.bank_name}</small></div>
                        <div><small className="text-muted">{row.bank_account_no}</small></div>
                    </TextNoWrap>
                )
            },
            width: '180px',
        },
        {
            title: 'สถานะ',
            render: (row: any) => {
                return (
                    <ListStatusBadge status={row.status} />
                )
            },
            width: '120px',
        },
        {
            title: 'เวลาทำรายการ',
            render: (row: any) => {
                const colorStatus = row.worker_status && Object.values(statusColor)[Object.keys(statusColor).indexOf(row.worker_status)]
                return (
                    <>
                        <div>{row.createdAt}</div>
                        <Badge className="d-block badge" bgColor={colorStatus}>{`worker: ${row.worker_status && Object.values(workerStatusTH)[Object.keys(workerStatusTH).indexOf(row.worker_status)]}`}</Badge>
                    </>
                )
            },
        },
        {
            title: 'จัดการ',
            render: (row: any) => {
                return (
                    <div>
                        {row.status === 'pending'
                            && (
                                <Space size={0}>
                                    <button className="btn btn-success btn-sm text-white me-2" disabled={btnLoading} onClick={() => { handleStatusData('approved', row.id, 'auto') }}>อนุมัติ</button>
                                    <button className="btn btn-warning btn-sm text-white me-2" disabled={btnLoading} onClick={() => { handleStatusData('approved', row.id, 'manual') }}>Manual</button>
                                    <button className="btn btn-danger btn-sm" disabled={btnLoading} onClick={() => { handleStatusData('reject', row.id) }}>ยกเลิก</button>
                                </Space>
                            )
                        }
                        {row.status === 'approved' && row.worker_status === 'error' &&
                            <div>
                                <Space size={0}>
                                    <button className="btn btn-warning btn-sm text-white me-2" onClick={() => { handleData(row); toggle(); }}>ทำซ้ำ</button>
                                </Space>
                            </div>
                        }
                        {row.worker_status === 'success' && row.transaction_log.transaction_id !== null
                            && (
                                <Space size={0}>
                                    <button className="btn btn-primary btn-sm text-white me-2" disabled={btnLoading} onClick={() => {
                                        handleViewData(row)
                                        toggleViewSlip()
                                    }}>สลิปโอนเงิน</button>
                                </Space>
                            )
                        }
                    </div>
                )
            },
            width: '220px',
        }
    ]

    useEffect(() => {
        if (Object.keys(list).length > 0) {
            // let filteredItems = (list as any).filter(
            //     (item: any) => (
            //         (item.title && item.title.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.bank_account_name && item.bank_account_name.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.bank_name && item.bank_name.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.bank_account_no && item.bank_account_no.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.account_bank_name && item.account_bank_name.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.company_name && item.company_name.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.remark && item.remark.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.created_user_name && item.created_user_name.toLowerCase().includes(filterText.toLowerCase())) ||
            //         (item.id && item.id.toString().includes(filterText.toLowerCase()))
            //     ),
            // );

            const fuseSearch = new Fuse<any>(list, {
                keys: ['title', 'bank_account_name', 'bank_name', 'bank_account_no', 'account_bank_name', 'company_name', 'remark', 'created_user_name', 'id'],
            })

            // if (filterStatus) {
            //     filteredItems = (filteredItems as any).filter(
            //         (item: any) => (
            //             (item.status && item.status.toString().includes(filterStatus.toLowerCase()))
            //         ),
            //     );
            // }
            // if (filterStatusWorker) {
            //     filteredItems = (filteredItems as any).filter(
            //         (item: any) => (
            //             (item.worker_status && item.worker_status.toString().includes(filterStatusWorker.toLowerCase()))
            //         ),
            //     );
            // }
            // if (filterCompany) {
            //     filteredItems = (filteredItems as any).filter(
            //         (item: any) => item.company_id === filterCompany,
            //     );
            // }

            // filteredItems.forEach((item: any, index: number) => { item.serial = index + 1; });
            if (filterText !== '') {
                setDataOjects(fuseSearch.search(filterText).map((x) => x.item))
            } else {
                setDataOjects(list)
            }
        } else {
            setDataOjects(list)
        }
    }, [list, filterText, setDataOjects, filterStatus, filterStatusWorker, filterCompany])


    const getCompanyOption = Object.values(companys).map((item: any) => {
        return <Option key={item.id} value={item.id}>{item.name}</Option>
    })

    return (
        <>
            <ListTableCard className="p-3 p-md-4 bg-white mb-4">
                <div className="card-head">
                    <div className="row mb-md-3 align-items-md-center">
                        <div className="col-12 col-md-auto mb-3 mb-md-0 mb-md-0">
                            <h2>รายการเบิก</h2>
                        </div>
                        <div className="col-12 col-md-auto ms-md-auto d-md-flex">
                            <button
                                className="btn btn-info ms-auto ms-md-0 me-2"
                                onClick={() => toggleFavorite()}
                            >
                                <i className="far fa-solid fa-star me-3"></i>
                                <span>รายการโปรด</span>
                            </button>
                            <button
                                className="btn btn-primary ms-auto ms-md-0"
                                onClick={() => { handleData({}); toggle(); }}
                            >
                                <i className="far fa-plus me-3"></i>
                                <span>เบิกค่าใช้จ่าย</span>
                            </button>
                        </div>
                    </div>
                    <div className="ms-md-auto filter-wrapper">
                        <div className="filter-group">
                            <Space
                                direction="vertical"
                                size={12}
                            >
                                <RangePicker
                                    // defaultValue={[moment().startOf('isoWeek'), moment().endOf('isoWeek')]}
                                    placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
                                    ranges={{
                                        'วันนี้': [moment(), moment()],
                                        'เมื่อวาน': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'อาทิตย์นี้': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
                                        'อาทิตย์ที่แล้ว': [moment().startOf('isoWeek').subtract(7, 'days'), moment().endOf('isoWeek').subtract(7, 'days')],
                                        'เดือนนี้': [moment().startOf('month'), moment().endOf('month')],
                                        'เดือนที่แล้ว': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                    }}
                                    format={dateFormat}
                                    onChange={onDateSearch}
                                />
                            </Space>
                            <button type="button" className="btn btn-outline-primary" onClick={onloadData}>ค้นหา</button>
                        </div>
                        <div className="filter-group me-0 me-xxl-3">
                            <label>Company :</label>
                            <Select
                                size="large"
                                dropdownStyle={{ minWidth: "10%" }}
                                value={filterCompany}
                                onSelect={(value: any) => {
                                    setFilterCompany(value)
                                }}
                            >
                                <Option value="">ทั้งหมด</Option>
                                {getCompanyOption}
                            </Select>
                        </div>
                        <div className="filter-group me-0 me-xxl-3">
                            <label>สถานะ :</label>
                            <Select
                                size="large"
                                dropdownStyle={{ minWidth: "10%" }}
                                value={filterStatus}
                                onSelect={(value: any) => {
                                    setFilterStatus(value)
                                }}
                            >
                                <Option value="">ทั้งหมด</Option>
                                <Option value="pending">รออนุมัติ</Option>
                                <Option value="approved">อนุมัติ</Option>
                                <Option value="reject">ไม่อนุมัติ</Option>
                            </Select>
                        </div>
                        <div className="filter-group me-0 me-xxl-3">
                            <label>Worker :</label>
                            <Select
                                size="large"
                                dropdownStyle={{ minWidth: "10%" }}
                                value={filterStatusWorker}
                                onSelect={(value: any) => {
                                    setFilterStatusWorker(value)
                                }}
                            >
                                <Option value="">ทั้งหมด</Option>
                                <Option value="pending">รอดำเนินการ</Option>
                                <Option value="processing">กำลังดำเนินการ</Option>
                                <Option value="verified">ตรวจสอบบัญชีแล้ว</Option>
                                <Option value="success">สำเร็จ</Option>
                                <Option value="error">ไม่สำเร็จ</Option>
                            </Select>
                        </div>
                        <div className="filter-group">
                            <Input
                                size="large"
                                addonBefore={<SearchOutlined style={{ marginBottom: '6px' }} />}
                                allowClear
                                placeholder="ค้นหา"
                                value={filterText ?? ''}
                                onChange={(e: any) => setFilterText(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <Table
                    columns={columns as any}
                    dataSource={dataOjects}
                    rowKey={() => nanoid()}
                    loading={loading}
                    scroll={{ x: true }}
                    sticky={{ offsetHeader: 57 }}
                    onChange={ScrollToTopPagination}
                    expandable={{ columnWidth: '100px' }}
                    // pagination={{
                    //     defaultPageSize: 20,
                    //     showSizeChanger: true,
                    //     pageSizeOptions: ['10', '20', '30', '40', '50', '80', '100'],
                    //     position: ['topRight', 'bottomRight'],
                    //     locale: { items_per_page: " รายการ" }
                    // }}


                    pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ['25', '50', '100', '200'],
                        position: ['topRight', 'bottomRight'],
                        locale: { items_per_page: " / Page" },
                        total: totalCount,
                        current: currentPage || 1,
                        showTotal: (total: number) => `ทั้งหมด ${total} รายการ`,
                        onChange: (page: number, pageSize: number) => {
                            onChangePagination({ page: page, pageSize: pageSize })
                        }
                    }}
                />
            </ListTableCard>
            <CreateTransaction
                accountList={accounts as any}
                companyList={companys as any}
                bankList={banks as any}
                isOpenCreateData={createTransactionModal}
                closeCreateData={() => toggle()}
                onloadData={() => onloadData()}
                transaction={duplicateTransaction as any}
            />
            <TransactionFav
                favList={listFav as any}
                accountList={accounts as any}
                companyList={companys as any}
                bankList={banks as any}
                isOpenCreateData={transactionFav}
                closeCreateData={() => toggleFavorite()}
                onloadData={() => onloadData()}
            />
            <ViewTransfer
                listData={transaction as any}
                isOpenViewData={viewDataDrawer}
                closeViewData={() => toggleViewData()}
            />
            <SlipModal
                data={transaction as any}
                isOpen={viewSlipModal}
                close={() => toggleViewSlip()}
            />
        </>
    );
}

export default TransferTable

const ListTableCard = styled.div`
    background: #fff;
    border-radius: 16px;

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: bold;
    }

    .ant-picker {
        min-width: 254px;
        width: 254px;
        margin-right: 8px;
    }
`

const Badge = styled.span<{ bgColor: string }>`
	font-weight: normal;
	color: rgb(${props => props.bgColor});
    background: rgba(${props => props.bgColor}, 0.1);
`

const TextNoWrap = styled.div`
    div, small {
        white-space: nowrap;
    }

    .wrap {
        white-space: break-spaces !important;
    }
`
