import baacIcon from '../assets/img/scb-slip/bank/baac.png'
import bayIcon from '../assets/img/scb-slip/bank/bay.png'
import bblIcon from '../assets/img/scb-slip/bank/bbl.png'
import cimbIcon from '../assets/img/scb-slip/bank/cimb.png'
import citiIcon from '../assets/img/scb-slip/bank/citi.png'
import ghIcon from '../assets/img/scb-slip/bank/ghb.png'
import gsbIcon from '../assets/img/scb-slip/bank/gsb.png'
import ibankIcon from '../assets/img/scb-slip/bank/ibank.png'
import icbcIcon from '../assets/img/scb-slip/bank/icbc.png'
import kbankIcon from '../assets/img/scb-slip/bank/kbank.png'
import kkIcon from '../assets/img/scb-slip/bank/kk.png'
import ktbIcon from '../assets/img/scb-slip/bank/ktb.png'
import lhIcon from '../assets/img/scb-slip/bank/lhb.png'
import scIcon from '../assets/img/scb-slip/bank/sc.png'
import scbIcon from '../assets/img/scb-slip/bank/scb.png'
import tbankIcon from '../assets/img/scb-slip/bank/tbank.png'
import tiscoIcon from '../assets/img/scb-slip/bank/tisco.png'
import ttbIcon from '../assets/img/scb-slip/bank/tmb.png'
import uobtIcon from '../assets/img/scb-slip/bank/uob.png'

export const bankNames: Record<string, string> = {
	KBANK: 'ธนาคารกสิกรไทย',
	BBL: 'ธนาคารกรุงเทพ',
	KTB: 'ธนาคารกรุงไทย',
	BAY: 'ธนาคารกรุงศรีอยุธยา',
	SCB: 'ธนาคารไทยพาณิชย์',
	KK: 'ธนาคารเกียรตินาคิน',
	CITI: 'ธนาคารซิตี้แบ้งค์',
	CIMB: 'ธนาคารซีไอเอ็มบี (ไทย)',
	TMB: 'ธนาคารทหารไทย',
	TTB: 'ธนาคารทหารไทยธนชาต',
	TISCO: 'ธนาคารทิสโก้',
	TBANK: 'ธนาคารธนชาต',
	BAAC: 'ธ.ก.ส.',
	UOBT: 'ธนาคารยูโอบี',
	LH: 'ธนาคารแลนด์ แอนด์ เฮ้าส',
	SC: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
	GSB: 'ธนาคารออมสิน',
	GH: 'ธนาคารอาคารสงเคราะห์',
	IBANK: 'ธนาคารอิสลามแห่งประเทศไทย',
	ICBC: 'ธนาคารไอซีบีซี (ไทย)',
	TRUEWALLET: 'ทรูมันนี่วอลเล็ท',
}

export const getBankIcon = (code: string) => {
	code = code.toUpperCase()
	switch (code) {
		case '004': {
			return kbankIcon
		}
		case '002': {
			return bblIcon
		}
		case '006': {
			return ktbIcon
		}
		case '025': {
			return bayIcon
		}
		case '014': {
			return scbIcon
		}
		case '069': {
			return kkIcon
		}
		case '017': {
			return citiIcon
		}
		case '022': {
			return cimbIcon
		}
		case '011': {
			return ttbIcon
		}
		case '067': {
			return tiscoIcon
		}
		case '065': {
			return tbankIcon
		}
		case '034': {
			return baacIcon
		}
		case '024': {
			return uobtIcon
		}
		case '073': {
			return lhIcon
		}
		case '020': {
			return scIcon
		}
		case '030': {
			return gsbIcon
		}
		case '033': {
			return ghIcon
		}
		case '066': {
			return ibankIcon
		}
		case '070': {
			return icbcIcon
		}
		default: {
			return ''
		}
	}
}
