import React, { Fragment, useEffect, useState } from 'react'
import BankTable from '../../components/Banks/BankTable'
import SendAPI from '../../utils/SendAPI'
const Banks = () => {
    const { sendAPIWithJWT } = SendAPI();
    const [loading, setLoading] = useState(true)
    const [dataAccounts, setDataAccounts] = useState<any>([])
    const getBanks = async () => {
        const dataResponse: any = await sendAPIWithJWT({
            path : "/banks",
            method : "get",
        });
        if (dataResponse.status === 200) {
            setDataAccounts(dataResponse.data?.data)
            setLoading(false)
        }
        return dataResponse
    }
    useEffect(() => {
        getBanks()
    }, [])// eslint-disable-line

    return (
        <Fragment>
            <div className="pt-3 pt-lg-5">
                <BankTable list={dataAccounts} loading={loading} onloadData={getBanks} />
            </div>
        </Fragment>
    )
}

export default Banks